var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-translations"},[_c('SettingsTopBar',{attrs:{"pathBefore":_vm.$t('Settings /'),"pathCurrent":_vm.$t('Translations'),"showBackButton":true,"backRoute":_vm.backRoute,"showSubmitButton":true,"submitButtonEvent":_vm.saveAllTranslationsEvent,"submitButtonText":_vm.$t('Spremi promjene')}}),(_vm.userHasRights===true)?_c('a-layout',{staticClass:"c-translations__layout"},[_c('a-layout-sider',{attrs:{"trigger":null,"width":"fit-content"}},[_c('a-menu',{staticStyle:{"width":"fit-content"},attrs:{"mode":"inline"}},_vm._l((_vm.availableTranslationObjects),function(translationObject){return _c('a-sub-menu',{key:'translation-' + translationObject.entityName,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(translationObject.label))+" ")])]},proxy:true}],null,true)},_vm._l((Object.entries(translationObject.attributes)),function(attribute){return _c('a-menu-item',{key:'translation-' +
              translationObject.entityName +
              '-' +
              attribute[0],on:{"click":function($event){return _vm.goToTranslationScreen(
                translationObject.entityName,
                attribute[0],
              )}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(attribute[1]))+" ")])])}),1)}),1)],1),_c('a-layout',[_c('a-layout-content',{staticClass:"c-translations__content"},[_c('router-view')],1)],1)],1):_c('NotAllowedComponent',{attrs:{"message":_vm.$t('You don’t have user rights for this module')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }