
import { Component, Vue } from 'vue-property-decorator';
import Translation from '@/models/Translation';
import TranslationRepository from '@/repositories/TranslationRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import NotAllowedComponent from '../components/views/settings/NotAllowedComponent.vue';

@Component({
  name: 'Translations',
  components: { SettingsTopBar,NotAllowedComponent},
})
export default class Translations extends mixins<UserRightsMixin>(
  UserRightsMixin,
) {
  private backRoute: RouteNames = RouteNames.settings;
  private saveAllTranslationsEvent = EventBusEvents.saveAllTranslations;
  private userHasRights: boolean = false;

  private get availableTranslationObjects() {
    const translation: Translation | null =
      TranslationRepository.getTranslation();

    if (translation == null) {
      return [];
    }
    return translation.getTranslatableObjects;
  }

  private goToTranslationScreen(entityName: string, attribute: string) {
    this.$router.push({
      name: RouteNames.translation,
      params: {
        entityName,
        attribute,
      },
    });
  }

  private async created() {
    try {
      if (this.canSeeTranslationsItem) {
        this.userHasRights = true;
        Translation.getAll();
      }
    } catch (e) {
      return;
    }
  }
}
